@use "sass:map";
@import "color-palettes";
@import "variables";


// MARK: cdk overlay
//below is a setting that affects all material pop ups on a page that scrolls
//it prevents the side nav and the background color from being scrolled as well
.cdk-global-scrollblock {
  position: static;             // this keeps the page from scrolling partially out of site
  overflow: hidden !important;  // remove this if you want to show the vertical scroll bar
}

// Below is a setting that affects all material pop ups
// It ensures the header bar is behind the cdk-overlay-container
.cdk-overlay-container {
  z-index: 2000 !important;
}

// MARK: Form Field
mat-form-field {
  width: 100%;

  &.form-input {
    font-size: .875rem;
    font-family: $font-family !important;
    line-height: 1rem;
  }
}

html {
  --mdc-filled-text-field-caret-color: #2ea2f8;
  --mdc-filled-text-field-focus-active-indicator-color: #2ea2f8;
  --mdc-filled-text-field-focus-label-text-color: #2ea2f8;
  --mdc-filled-text-field-container-color: #fff;
  --mdc-filled-text-field-input-text-color: #1F1F1F; // map.get($ink-palette, 900)
  --mdc-filled-text-field-label-text-font: "Open Sans", serif;
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mat-form-field-container-text-font: "Open Sans", serif;
  --mat-form-field-subscript-text-font: "Open Sans", serif;
}

.mat-mdc-text-field-wrapper.mdc-text-field--filled {
  --mdc-filled-text-field-active-indicator-height: 0px;
  --mdc-filled-text-field-focus-active-indicator-height: 0px;
  --mdc-filled-text-field-container-shape: 4px;
  border-radius: var(--mdc-filled-text-field-container-shape);
  border: $form-input-border;

  &:not(.mdc-text-field--disabled) {
    &.mdc-text-field--focused {
      border: 1px solid $form-focus-border-color;
    }
  
    &.mdc-text-field--invalid {
      border: 1px solid map.get($error-palette, 500);
    }
  }
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-text-field-wrapper {
  .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px;
  }

  &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 23px;
    padding-bottom: 9px;
    min-height: unset;
  }

  .mat-mdc-select-arrow-wrapper {
    height: 16px;
    align-items: flex-end;
  }
}

// MARK: Select
html {
  --mat-option-selected-state-label-text-color: #0073D0; // map.get($primary-palette, 500);
  --mat-option-selected-state-layer-color: #e5f5fc; 
}

html {
  --mat-select-trigger-text-font: "Open Sans", serif;
  --mat-select-trigger-text-line-height: 1rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-focused-arrow-color: #0073D0; // map.get($primary-palette, 500);
}

.mat-mdc-select,
.mat-mdc-floating-label {
  font-family: $font-family !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  overflow: hidden;
}

html {
  --mat-option-label-text-font: "Open Sans", serif;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: normal;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0073D0; // map.get($primary-palette, 500);
}

.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mat-checkbox-indeterminate.mat-accent .mdc-checkbox__background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-mdc-ripple-element {
  background-color: #3185FC !important;
}

.mat-mdc-select-panel {
  position: relative;
  top: 2.75rem;
  left: 0.25rem;
  padding: 0px !important;
}

.mat-mdc-radio-button {

  .mat-radio-container {
    margin: 0.15rem 0 0;
    width: 1rem;
    height: 1rem;
  }

  .mat-radio-ripple {
    left: calc(50% - 1rem) !important;
    top: calc(50% - 1rem) !important;
    height: 2rem !important;
    width: 2rem !important;
  }

  &.mat-mdc-radio-checked {
    .mat-radio-persistent-ripple {
      background-color: lighten($primary, 40%) !important;
    }

    .mat-mdc-radio-button.mat-accent:active {
      .mat-radio-persistent-ripple {
        background-color: lighten($primary, 40%) !important;
      }
    }
  }
}

.mat-mdc-radio-button.mat-accent {
  .mdc-radio__inner-circle {
    background-color: $white;
  }

  &.mat-mdc-radio-checked .mdc-radio__outer-circle {
    border-color: transparent;
  }
}

.mat-radio-container {
  .mdc-radio__outer-circle {
    background-color: $white;
    border: 1px solid #e6e7e8;
    height: 1rem !important;
    width: 1rem !important;
    background-image: linear-gradient(to top, #f2f4f7, $white) !important;
  }
}

.mdc-label {
  color: $header-font-color;
  font-family: $font-family;
}


.mat-mdc-radio-checked {
  .mat-radio-container {
    .mdc-radio__outer-circle {
      background-color: $primary;
      height: 1rem !important;
      width: 1rem !important;
      background-image: none !important;
    }
    .mdc-radio__inner-circle {
      height: 0.875rem !important;
      width: 0.875rem !important;
      top: 0.063rem;
      left: 0.063rem;
    }
  }
  .mdc-label {
    font-weight: 600;
  }
}

.modal + .cdk-overlay-container {
  z-index: 1051 !important;
}

.mat-mdc-select,
.mat-mdc-floating-label {
  font-family: $font-family !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-mdc-slide-toggle {
  &.mat-primary.mat-checked {
    .mat-slide-toggle-bar, .mat-mdc-ripple-element {
      background-color: $primary-alpha;
    }
    .mat-slide-toggle-thumb {
      background-color: $primary;
    }
  }
}

.cdk-overlay-pane {
  max-width: 60vw !important;
}

//BUTTONS:
// MARK: Button
.mdc-button {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0073d0;
  --mat-mdc-button-persistent-ripple-color: #0073d0;
  --mat-mdc-button-ripple-color: #0073d01a;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0073d0;
  &:disabled {
    --mdc-filled-button-disabled-container-color: #0073D040; // map.get($primary-palette, 100)
    --mdc-filled-button-disabled-label-text-color: #ffffff;
  }
}

.mat-mdc-outlined-button {
  background-image: linear-gradient(to top, #f2f4f7, $white) !important;
  border: $form-input-border !important;
  &.mat-primary {
    color: #495057;
    --mdc-outlined-button-label-text-color: #495057;
    --mat-mdc-button-persistent-ripple-color: #0073d0;
    --mat-mdc-button-ripple-color: #0073d01a;
  }
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0073d0;
}

html {
  --mat-icon-color: #777E85; // map.get($ink-palette, 600);
}

.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0073d0;
  --mat-mdc-button-persistent-ripple-color: #0073d0;
  --mat-mdc-button-ripple-color: #0073d01a;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mdc-fab-container-color: #ffffff;
  --mat-icon-color: #1F1F1F;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0073d0;
  --mat-icon-color: #ffffff;
}

html {
  --mat-standard-button-toggle-selected-state-background-color: #0073d0;
  --mat-standard-button-toggle-selected-state-text-color: #ffffff;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #ffffff;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #0073d040;
  --mat-standard-button-toggle-divider-color: #ced4da;
  --mat-standard-button-toggle-height: 36px;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-text-font: "Open Sans", serif; // Delete after Material 17 Upgrade

  // Below are variables introduced in Material 17
  --mat-standard-button-toggle-label-text-font: "Open Sans", serif;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-weight: 600;
}

:host ::ng-deep .mat-button-toggle-appearance-standard {
  background-image: linear-gradient(to top, #f2f4f7, #FFF);

  &.mat-button-toggle-checked {
    background-image: unset;
  }
}

:host ::ng-deep .mat-button-toggle-button {
  min-width: 64px;

  font-size: 14px; // Delete after Material 17 Upgrade
  font-weight: 600; // Delete after Material 17 Upgrade
  .mat-button-toggle-label-content {
    padding: 0 16px;
  }
}


//TABLE
// MARK: Table
.mat-mdc-table {
  font-weight: 400;
  color: map.get($ink-palette, 900);

  .mat-mdc-header-row {
    min-height: 36px;
    padding-top: 1rem;
    border-bottom: solid 1px map.get($ink-palette, 300);
    .mat-mdc-header-cell {
      font-size: 10px;
      line-height: 1;
      color: map.get($primary-palette, 900);

      padding: 0 0.625rem 0 1rem;

      &:last-child {
        margin-left: auto;
      }
    }
  }

  .mat-mdc-row {
    min-height: 36px;
    border-bottom: none;
    .mat-mdc-cell {
      padding: 0 0.625rem 0 1rem;
    }
    &:last-child {
      margin-left: auto;
    }
    &:nth-child(even){background-color: #f1f4f8;}
    &:hover, &:focus {
      background-color: map.get($primary-palette, 50) !important;
      outline: map.get($primary-palette, 500) auto 1px;
    }
    &.selected {
      background-color: map.get($primary-palette, 50) !important;
    }
  }
}

// on next upgrade make the dialog styles the same as the accounts dialog if possible
// DIALOG: 
.mat-mdc-dialog-container {
  border-radius: 10px;
  box-shadow: 0 10px 34px 0 rgba(210, 210, 210, 0.5);
  min-width: 450px;

  mat-dialog-content {
    padding: 0px;
    min-height: unset;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: solid 2px $border-color;

    &.mat-typography p {
      margin-bottom: 1rem;
    }
    .row {
      justify-content: center;
    }
  }

  mat-dialog-actions {
    padding-top: 24px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    display: block;
  }
}
